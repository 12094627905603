









































import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  SetupContext,
} from '@vue/composition-api'
import TableColumnHeader from './TableColumnHeader.vue'
import TableRow from './TableRow.vue'
import ColumnData from './js/column'
import Row from './js/row.js'
import { VNode } from 'vue'
import { TypeColumn } from 'src/types/common'

type tableObject = { [key: string]: string | number }
type Props = {
  placeholder: string
  data: tableObject[]
}
export default defineComponent({
  components: {
    TableColumnHeader,
    TableRow,
  },
  props: {
    placeholder: {
      type: String,
      default: '該当するデータがありません。',
    },
    data: {
      type: Array as PropType<tableObject[]>,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const columns = ref<TypeColumn[]>([])
    const data = ref<tableObject[]>(props.data)

    onMounted(() => {
      const columnSlots = context.slots
      if (typeof columnSlots.default !== 'undefined') {
        const columnComponents = columnSlots.default()
        const columnComponentsList = columnComponents
          .filter((column: VNode) => column.componentInstance)
          .map((column) => column.componentInstance)

        const column = columnComponentsList?.map(
          (column) => new ColumnData(column)
        )

        columns.value = column as TypeColumn[]
      }
    })

    // computed
    const isEmpty = computed(() => {
      return props.data.length === 0
    })

    // TODO:: 用途不明（別ファイルから呼び出してる？）
    const rows = computed(() => getRowsData())

    // TODO:: classを返す（なぜcomputedなのか不明）
    const fullTableClass = computed(() => {
      return 'w-full bg-white text-12px table-fixed'
    })
    const fullTableHeadClass = computed(() => {
      return 'text-left h-50px bg-gray_f0f font-hiragino-w6 break-words'
    })
    const fullTableBodyClass = computed(() => {
      return 'text-left text-black_444 font-hiragino-w3 break-words'
    })

    // methods
    const getRowsData = (): Row[] => {
      return data.value.map((rowData) => new Row(rowData, columns))
    }
    const rowClick = (val: object) => {
      context.emit('click', val)
    }

    return {
      rows,
      columns,
      isEmpty,
      fullTableClass,
      fullTableHeadClass,
      fullTableBodyClass,
      rowClick,
    }
  },
})
