































import {
  defineComponent,
  PropType,
  ref,
  inject,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import { keyFormWidth } from './BaseForm.vue'

type mode = 'none' | 'contractStatus' | 'examinationStatus' | 'sendStatus'
type Props = {
  label: string
  labelWidth: number
  required: boolean
  mode: mode
  code: string | number
}
export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: Number,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<mode>,
      default: 'none',
    },
    code: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props: Props) {
    // data
    const vm = getCurrentInstance()
    const isMobile = ref<boolean>(Boolean(vm!.proxy.$device.mobile))
    const formWidth = inject(keyFormWidth)

    // computed
    const labelStyle = computed((): string => {
      const labelWidth = props.labelWidth || formWidth
      return typeof labelWidth !== 'undefined'
        ? 'width:' + labelWidth + 'px'
        : ''
    })
    const replaceLabel = computed((): string => {
      return props.label.replace(/\n/g, '<br/>')
    })
    const requiredLabel = computed((): string => {
      const required = props.required
        ? '<span class="text-red_ea1">*</span>'
        : ''

      return (props.label + required).replace(/\n/g, '<br/>')
    })
    const bgStyle = computed((): string => setClass())

    // methods
    const setClass = (): string => {
      let strClass = ''
      if (props.mode === 'none') return strClass

      if (props.mode === 'contractStatus') {
        switch (props.code) {
          case 1:
          case 2:
            strClass = 'bg-green_ccf'
            break
          case 4:
          case 7:
            strClass = 'bg-red_ffc'
            break
          case 5:
            strClass = 'bg-yellow_fff'
            break
          default:
            strClass = ''
        }
      } else if (props.mode === 'examinationStatus') {
        switch (props.code) {
          case 1:
            strClass = 'bg-purple_e5c'
            break
          case 2:
            strClass = 'bg-orange_ffe'
            break
          case 3:
            strClass = 'bg-yellow_fff'
            break
          case 5:
            strClass = 'bg-red_ffc'
            break
          default:
            strClass = ''
        }
      } else if (props.mode === 'sendStatus') {
        if (props.code === 1) {
          strClass = 'bg-red_ffc'
        } else if (props.code === 2) {
          strClass = 'bg-gray_ccc'
        } else {
          strClass = ''
        }
      }
      return strClass
    }

    return {
      isMobile,
      labelStyle,
      replaceLabel,
      requiredLabel,
      bgStyle,
    }
  },
})
