
































import Vue from 'vue'
import Test from 'src/components/Test.vue'
import Header from 'src/components/molecules/header.vue'
import Footer from 'src/components/molecules/footer.vue'
import Sidebar from 'src/components/molecules/sidebar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Test,
    Header,
    Footer,
    Sidebar,
  },
  data() {
    return {
      menuList: [
        {
          index: 1,
          title: 'テストメニューTOP',
          link: '/',
        },
        {
          index: 2,
          title: 'テストメニュー1',
          link: '/setting',
        },
        {
          index: 3,
          title: 'テストメニュー2',
          link: '/contracts/updateFiles/1',
          display: 'WARNING',
        },
        {
          index: 4,
          title: 'テストメニュー3',
          link: '/updateTerms/1',
          display: 'SHOW',
        },
        {
          index: 5,
          title: 'テストメニュー4',
          link: '/updateRequest',
        },
        {
          index: 6,
          title: 'テストメニュー5',
          link: '/manual',
        },
      ],
      selectedMenu: 1,
      isOpenSideMenu: true,
    }
  },
  methods: {
    transitionMenu(link: string) {
      console.log('遷移処理 - ', link)
    },
    menuSelect(select: number) {
      this.selectedMenu = select
    },
    clickHeaderMenu() {
      this.isOpenSideMenu = !this.isOpenSideMenu
    },
    logout() {
      console.log('ログアウト処理')
    },
  },
})
