














import {
  defineComponent,
  PropType,
  computed,
  SetupContext,
} from '@vue/composition-api'

type buttonTheme = 'blue' | 'thin-blue' | 'red' | 'gray'
type Props = {
  theme: buttonTheme
  disabled: boolean
  width: string
  type: string
}
export default defineComponent({
  props: {
    theme: {
      type: String as PropType<buttonTheme>,
      default: 'blue',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-125px',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  setup(props: Props, context: SetupContext) {
    // computed
    const themeStyle = computed((): string => {
      if (props.disabled) {
        return (
          'bg-gray_f0f border border-gray_d4d text-gray_adb hover:bg-gray_e4e' +
          ' ' +
          props.width
        )
      }

      switch (props.theme) {
        case 'thin-blue':
          return (
            'bg-blue_c8e text-blue_197 hover:bg-blue_197 hover:text-white' +
            ' ' +
            props.width
          )

        case 'red':
          return 'bg-red_ef5 text-white hover:bg-red_bd1' + ' ' + props.width

        case 'gray':
          return (
            'bg-gray_f6f border border-gray_d4d text-black_444 hover:bg-gray_e4e' +
            ' ' +
            props.width
          )

        case 'blue':
        default:
          return 'bg-blue_3f9 text-white hover:bg-blue_197' + ' ' + props.width
      }
    })
    // methods
    const handleClick = (e: Event): void => {
      const target = e.target as HTMLButtonElement
      // ボタンを押下したらフォーカスを外す
      target.blur()
      context.emit('click')
    }

    return {
      themeStyle,
      handleClick,
    }
  },
})
