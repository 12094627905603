













import { defineComponent, SetupContext } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, context: SetupContext) {
    const handleCheck = (e: Event): void => {
      const target = e.target as HTMLInputElement
      context.emit('input', target.value)
    }
    return {
      handleCheck,
    }
  },
})
