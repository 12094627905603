








import { defineComponent, getCurrentInstance, ref } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const vm = getCurrentInstance()
    const isMobile = ref<boolean>(Boolean(vm!.proxy.$device.mobile))

    return {
      isMobile,
    }
  },
})
