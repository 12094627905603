import Vue from 'vue'
import App from './App.vue'
import VueCompositionAPI from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import { createI18n, castToVueI18n } from 'vue-i18n-bridge'
import device from 'vue-device-detector'
import 'src/plugin/components.js'
import 'src/plugin/svgIcon.js'
import 'src/assets/css/index.css'

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(device)

Vue.use(VueI18n, { bridge: true })
const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: 'ja',
      messages: require('src/locales/messages'),
    },
    VueI18n
  )
)
Vue.use(i18n)
new Vue(App).$mount('#app')

// const app = createApp({
//   render: (h: CreateElement) => h(App),
//   i18n,
// })
// app.mount('#app')
