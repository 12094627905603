









import {
  defineComponent,
  ref,
  toRef,
  InjectionKey,
  provide,
  getCurrentInstance,
} from '@vue/composition-api'

type Props = {
  labelWidth: number | undefined
}
export default defineComponent({
  props: {
    labelWidth: {
      type: Number,
      default: undefined,
    },
  },
  setup(props: Props) {
    // data
    const vm = getCurrentInstance()
    const isMobile = ref<boolean>(Boolean(vm!.proxy.$device.mobile))
    const formWidth = toRef(props, 'labelWidth')
    provide(keyFormWidth, formWidth.value)

    return {
      isMobile,
    }
  },
})

// provideメソッドに指定するInjectionKeyを指定
export const keyFormWidth: InjectionKey<number | undefined> =
  Symbol('formWidth')
