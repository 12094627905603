























import Vue from 'vue'
import {
  defineComponent,
  ref,
  computed,
  SetupContext,
} from '@vue/composition-api'

type Props = {
  value: string
  width: string
  height: string
  disabled: boolean
  readonly: string
  maxlength: number
  isError: boolean
}
export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: 'max-w-sm sm:max-w-md md:max-w-lg lg:max-w-3xl xl:max-w-3xl',
    },
    height: {
      type: String,
      default: '400px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // data
    const isMobile = ref<boolean>(Boolean(Vue.$device.mobile))

    // computed
    const disableClass = computed((): string => {
      return props.disabled
        ? 'bg-gray_f0f text-gray_adb cursor-not-allowed'
        : ''
    })
    const errorClass = computed((): string => {
      return props.isError ? 'border-red_ea1 bg-red_fae' : ''
    })
    // methods
    const handleInput = (e: Event): void => {
      const target = e.target as HTMLTextAreaElement
      context.emit('input', target.value)
    }

    return {
      isMobile,
      disableClass,
      errorClass,
      handleInput,
    }
  },
})
