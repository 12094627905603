
















































import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  SetupContext,
} from '@vue/composition-api'
import { useI18n } from 'vue-i18n-bridge'
import { Option } from '~/src/types/common'

type size = 'small' | 'large'
type Props = {
  value: string | number
  widthType: size
  optionItems: Option[]
  disabled: boolean
  initialSelectable: boolean
  isError: boolean
}

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    widthType: {
      type: String as PropType<size>,
      default: 'small',
    },
    optionItems: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialSelectable: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // i18n
    const { t } = useI18n()
    // data
    const { selectBox, selectOptions, isSelectOpen, openSelect, closeSelect } =
      useDropDown(props)
    const initialValue = ref<string | number>('')
    // created
    switch (typeof props.value) {
      case 'string':
        initialValue.value = ''
        break

      case 'number':
        initialValue.value = 0
        break

      default:
        initialValue.value = ''
    }
    // mounted
    onMounted(() => {
      window.addEventListener('click', closeSelect)
    })
    // beforeDestroy
    onBeforeUnmount(() => {
      window.removeEventListener('click', closeSelect)
    })
    // computed
    const stateStyle = computed((): string => {
      if (props.disabled) {
        return 'bg-gray_f0f text-gray_adb'
      }

      if (props.isError) {
        return 'bg-red_fae border-red_ea1 focus:shadow-blue-3f9'
      } else {
        return 'focus:shadow-blue-3f9'
      }
    })
    const selectedText = computed((): string => {
      const selectedItem = props.optionItems.find(
        (item) => item.value === props.value
      )

      return selectedItem ? selectedItem.text : t('選択する') + ''
    })
    // methods
    const selectInitial = (props: Props): void => {
      if (props.initialSelectable) props.value = initialValue.value
    }
    const setSelectValue = (item: string | number): void => {
      context.emit('input', item)
    }

    return {
      t,
      selectBox,
      selectOptions,
      isSelectOpen,
      openSelect,
      closeSelect,
      initialValue,
      stateStyle,
      selectedText,
      selectInitial,
      setSelectValue,
    }
  },
})

// TODO:: 切り出し
// ユーザ操作関連
const useDropDown = (props: Props) => {
  // data
  const selectBox = ref<HTMLCanvasElement | null>(null)
  const selectOptions = ref<HTMLCanvasElement | null>(null)
  const isSelectOpen = ref<boolean>(false)

  // methods
  const openSelect = () => {
    if (!props.disabled) isSelectOpen.value = true
    setTimeout(adjustListPosition, 0)
  }
  const closeSelect = (event: Event) => {
    if (selectBox.value !== event.target) {
      isSelectOpen.value = false
    }
  }
  const adjustListPosition = () => {
    if (selectOptions.value instanceof HTMLElement) {
      const overPixels: number =
        document.body.getBoundingClientRect().bottom -
        selectOptions.value.getBoundingClientRect().bottom

      if (overPixels < 0) {
        selectOptions.value.style.transform =
          'translateY(' + (overPixels - 24) + 'px)'
      }
    }
  }
  return {
    selectBox,
    selectOptions,
    isSelectOpen,
    openSelect,
    closeSelect,
  }
}
