




























import {
  defineComponent,
  ref,
  onMounted,
  computed,
  SetupContext,
  getCurrentInstance,
} from '@vue/composition-api'
import { RadioGroupData } from './BaseRadioGroup.vue'

type Props = {
  value: string | number
  name: string
  label: string | number
  isDisabled: boolean
}
export default defineComponent({
  props: {
    value: {
      type: [String, Number],
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // data
    const radioGroup = ref<RadioGroupData>()
    const radioInput = ref<HTMLElement>()
    const isGroup = computed(() => {
      return typeof radioGroup.value !== 'undefined'
    })

    // mounted
    onMounted(() => setRadioGroup())
    const radioValue = computed({
      get: () => getRadioValue(),
      set: (val: string | number) => setRadioValue(val),
    })

    // methods
    const setRadioGroup = () => {
      const vm = getCurrentInstance()
      radioGroup.value = vm!.proxy.$parent.$data as RadioGroupData
    }
    const disabled = computed(() => {
      return isGroup.value
        ? radioGroup.value!.isGroupDisabled
        : props.isDisabled
    })
    const getRadioValue = (): string | number => {
      return isGroup.value ? radioGroup.value!.groupValue : props.value
    }
    const setRadioValue = (val: string | number) => {
      if (isGroup.value) {
        radioGroup.value!.handleChange(val)
      } else {
        context.emit('input', val)
      }
      const inputElement = radioInput.value as HTMLInputElement
      if (inputElement) {
        inputElement.checked = radioValue.value === props.label
      }
    }

    const styles = computed(() => {
      const classes = []
      if (disabled.value) {
        classes.push('text-checkbox-disable-bg', 'cursor-not-allowed')
      } else {
        classes.push('focus:border-blue_3f9', 'focus:text-blue_3f9')
      }
      return classes.join(' ')
    })
    const tabIndex = computed(() => {
      return disabled.value ||
        (isGroup.value && radioValue.value !== props.label)
        ? -1
        : 0
    })

    return {
      radioValue,
      disabled,
      styles,
      tabIndex,
      setRadioValue,
    }
  },
})
