
import {
  defineComponent,
  PropType,
  ref,
  computed,
  h,
} from '@vue/composition-api'
import { TypeColumn, TypeRow } from '~/src/types/common'
import { VNodeChildren } from 'vue'

type Attr = {
  attrs: {
    class: string
  }
}
type Props = {
  column: TypeColumn
  row: TypeRow
}
export default defineComponent({
  props: {
    column: {
      type: Object as PropType<TypeColumn>,
    },
    row: {
      type: Object as PropType<TypeRow>,
    },
  },
  setup(props: Props) {
    const column = ref<TypeColumn>(props.column as TypeColumn)
    const render = () => {
      const data: Attr = {
        attrs: {
          class: classStyle.value,
        },
      }
      if (typeof props.column.template === 'undefined') {
        return h('td', { ...data }, props.row[column.value.label] + '')
      } else {
        return h(
          'td',
          { ...data },
          props.column.template(props.row) as VNodeChildren
        )
      }
    }
    const classStyle = computed((): string => {
      let classStr = ''

      if (column.value.theme === 'no-border') {
        classStr += 'relative border-b border-gray_d4d px-12px cursor-pointer'
      } else if (column.value.theme === 'border') {
        classStr += 'relative border border-gray_d4d px-12px'
      }

      return classStr
    })

    return () => render()
  },
})
