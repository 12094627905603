import { pick } from './utils.js'

export default class ColumnData {
  constructor(columnComponent) {
    const properties = pick(columnComponent, [
      'show',
      'label',
      'width',
      'theme',
    ])

    for (const property in properties) {
      this[property] = columnComponent[property]
    }
    this.template = columnComponent.$scopedSlots.default
  }
}
