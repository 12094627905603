














































import { defineComponent, computed, SetupContext } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-bridge'

type Props = {
  value: number
  total: number
  pageSize: number
  showNumber: number
  hidePageInfo: boolean
}

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    showNumber: {
      type: Number,
      default: 10,
    },
    hidePageInfo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // i18n
    const { t } = useI18n()

    // computed
    // ページ数を計算
    const pages = computed((): number[] => {
      const len = Math.ceil(props.total / props.pageSize)
      const arr = []
      for (let i = 1; i <= len; i++) {
        arr.push(i)
      }
      return arr
    })
    // 最初のページか
    const isFirstPage = computed((): boolean => {
      return props.value === 1
    })
    // 最後のページか
    const isLastPage = computed((): boolean => {
      return props.value === pages.value.length || pages.value.length === 0
    })
    // 複数ページが存在するか
    const isMultiplePages = computed((): boolean => {
      return pages.value.length > 1
    })
    // ページ一覧
    const showItems = computed((): number[] => {
      if (pages.value.length <= props.showNumber) {
        return [...pages.value]
      }
      let start = Math.max(props.value - Math.ceil(props.showNumber / 2), 0)
      const end = Math.min(pages.value.length, props.showNumber + start)
      if (end - start < props.showNumber) {
        start = end - props.showNumber
      }
      return pages.value.slice(start, end)
    })
    // 件数の情報を表示
    const info = computed((): string => {
      const indexStart = (props.value - 1) * props.pageSize
      const indexEnd = Math.min(indexStart + props.pageSize, props.total)
      return `${indexStart + 1} 〜 ${indexEnd} ` + t('件を表示')
    })

    // methods
    const changePage = (type: 'prev' | 'next') => {
      switch (type) {
        case 'prev':
          if (!isFirstPage.value) {
            handleClick(props.value - 1)
          }
          break
        case 'next':
          if (!isLastPage.value) {
            handleClick(props.value + 1)
          }
          break
      }
    }
    const handleClick = (current: number) => {
      if (current && current !== props.value) {
        input(current)
      }
    }
    // Emit
    const input = (val: number) => {
      context.emit('input', val)
    }

    return {
      isFirstPage,
      isLastPage,
      isMultiplePages,
      showItems,
      info,
      changePage,
      handleClick,
    }
  },
})
