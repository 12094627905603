































































import {
  defineComponent,
  PropType,
  SetupContext,
  getCurrentInstance,
  ref,
  toRef,
} from '@vue/composition-api'
import { Menu } from '~/src/types/sidebar'

type Props = {
  menuList: Menu[]
  selectedMenu: number
  title: string
}
export default defineComponent({
  props: {
    menuList: {
      type: Array as PropType<Menu[]>,
      required: true,
    },
    selectedMenu: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const vm = getCurrentInstance()
    const isMobile = ref<boolean>(Boolean(vm!.proxy.$device.mobile))
    const menuSelected = toRef(props, 'selectedMenu')

    const isShow = (item: Menu): boolean => {
      return !('display' in item && item.display === 'HIDE')
    }

    const menuStyle = (item: Menu): string => {
      if (!('display' in item)) {
        return ''
      }
      switch (item.display) {
        case 'SHOW':
          return 'text-black_444'
        case 'WARNING':
          return 'text-red_ea1'
        case 'HIDE':
        default:
          return 'text-black_444'
      }
    }

    // メニューアコーディオンのリンクからの遷移処理
    const toggleMenu = (link: string): void => {
      // 遷移
      context.emit('transition', link)

      // モバイルの場合、遷移時にメニューを閉じる
      if (isMobile.value) {
        closeMenu()
      }
    }

    // メニューアコーディオンの選択状態
    const resetRouteIndex = (select: number) => {
      context.emit('selected', select)
    }

    const closeMenu = () => {
      context.emit('close')
    }

    const logout = () => {
      context.emit('logout')
    }

    return {
      isMobile,
      menuSelected,
      isShow,
      menuStyle,
      toggleMenu,
      resetRouteIndex,
      closeMenu,
      logout,
    }
  },
})
