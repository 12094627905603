



















import { defineComponent, SetupContext } from '@vue/composition-api'

type Props = {
  value: string
  placeholder: string
  disabled: boolean
}

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    const handleInput = (e: Event): void => {
      const target = e.target as HTMLInputElement
      context.emit('input', target.value)
    }
    const click = (e: Event) => {
      context.emit('click', e)
    }

    return {
      handleInput,
      click,
    }
  },
})
