








import { defineComponent, PropType } from '@vue/composition-api'

type theme = 'border' | 'no-border'

export default defineComponent({
  props: {
    show: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    theme: {
      type: String as PropType<theme>,
      default: 'border',
    },
    code: {
      type: [String, Number],
      default: '',
    },
  },
})
