import Vue from 'vue'
import Form from 'src/components/atoms/BaseForm/BaseForm.vue'
import FormItem from 'src/components/atoms/BaseForm/BaseFormItem.vue'
import Label from 'src/components/atoms/BaseLabel.vue'
import Input from 'src/components/atoms/BaseInput.vue'
import TextArea from 'src/components/atoms/BaseTextarea.vue'
import Select from 'src/components/atoms/BaseSelect.vue'
import MultiSelect from 'src/components/atoms/BaseMultiSelect/BaseMultiSelect.vue'
import Button from 'src/components/atoms/BaseButton.vue'
import IconButton from 'src/components/atoms/BaseIconButton.vue'
import CardButton from 'src/components/atoms/BaseCardBtn.vue'
import FileUpload from 'src/components/atoms/BaseFileUpload.vue'
import FileDownload from 'src/components/atoms/BaseFileDownload.vue'
import Calendar from 'src/components/atoms/BaseCalendar/BaseCalendar.vue'
import CheckBox from 'src/components/atoms/BaseCheckBox.vue'
import RadioGroup from 'src/components/atoms/BaseRadio/BaseRadioGroup.vue'
import Radio from 'src/components/atoms/BaseRadio/BaseRadio.vue'
import Table from 'src/components/atoms/BaseTable/TableIndex.vue'
import TableColumn from 'src/components/atoms/BaseTable/TableColumn.vue'
import Pagination from 'src/components/atoms/BasePagination.vue'
import RichTextarea from 'src/components/atoms/BaseRichTextarea.vue'
import BaseSvgIcon from 'src/components/atoms/BaseSvgIcon.vue'

Vue.component('SmfInput', Input)
Vue.component('SmfTextarea', TextArea)
Vue.component('SmfSelect', Select)
Vue.component('SmfMultiSelect', MultiSelect)
Vue.component('SmfCheckBox', CheckBox)
Vue.component('SmfButton', Button)
Vue.component('SmfIconButton', IconButton)
Vue.component('SmfCardButton', CardButton)
Vue.component('SmfForm', Form)
Vue.component('SmfFormItem', FormItem)
Vue.component('SmfLabel', Label)
Vue.component('SmfPagination', Pagination)
Vue.component('SmfFileUpload', FileUpload)
Vue.component('SmfFileDownload', FileDownload)
Vue.component('SmfCalendar', Calendar)
Vue.component('SmfRadioGroup', RadioGroup)
Vue.component('SmfRadio', Radio)
Vue.component('SmfTable', Table)
Vue.component('SmfTableColumn', TableColumn)
Vue.component('SmfRichTextarea', RichTextarea)
Vue.component('SmfIcon', BaseSvgIcon)
