






import { defineComponent, SetupContext, toRef } from '@vue/composition-api'

type Props = {
  value: string | number
  isDisabled: boolean
  radioTag: string
}
export type RadioGroupData = {
  groupValue: string | number
  isGroupDisabled: boolean
  handleChange: (value: string | number) => void
}
export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    radioTag: {
      type: String,
      default: 'div',
    },
  },
  setup(props: Props, context: SetupContext) {
    // data
    const groupValue = toRef(props, 'value')
    const isGroupDisabled = toRef(props, 'isDisabled')
    // methods
    const handleChange = (value: string | number) => {
      context.emit('input', value)
    }

    return {
      groupValue,
      isGroupDisabled,
      handleChange,
    }
  },
})
