























import {
  defineComponent,
  PropType,
  computed,
  SetupContext,
} from '@vue/composition-api'

type iconType = 'l-arrow' | 'r-arrow' | 'cancel'
type buttonTheme = 'gray' | 'blue'
type Props = {
  iconType: iconType
  theme: buttonTheme
  disabled: boolean
  type: string
}
export default defineComponent({
  props: {
    iconType: {
      type: String as PropType<iconType>,
      default: 'l-arrow',
    },
    theme: {
      type: String as PropType<buttonTheme>,
      default: 'gray',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  setup(props: Props, context: SetupContext) {
    // computed
    const themeStyle = computed((): string => {
      if (props.disabled) {
        return 'bg-gray_f0f border-gray_d4d text-gray_adb cursor-default'
      }

      switch (props.theme) {
        case 'blue':
          return 'bg-transparent border-blue_197 text-blue_3f9 hover:bg-blue_3f9 hover:border-blue_3f9 hover:text-white'

        case 'gray':
        default:
          return 'bg-gray_f6f border-gray_d4d text-black_444 hover:bg-gray_e4e'
      }
    })
    const iconName = computed((): string => {
      switch (props.iconType) {
        case 'r-arrow':
          return 'fa-angle-right'

        case 'cancel':
          return 'fa-times'

        case 'l-arrow':
        default:
          return 'fa-angle-left'
      }
    })
    const svgClass = computed((): string => {
      switch (props.iconType) {
        case 'r-arrow':
          return 'right-0 mr-12px'

        case 'cancel':
          return ''

        case 'l-arrow':
        default:
          return 'left-0 ml-12px'
      }
    })

    // methods
    const handleClick = (e: Event): void => {
      context.emit('click', e)
    }

    return {
      themeStyle,
      iconName,
      svgClass,
      handleClick,
    }
  },
})
