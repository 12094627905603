



































import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  SetupContext,
} from '@vue/composition-api'
import format from 'date-fns/format'
import Calendar from './BaseCalendarView.vue'

type Props = {
  value: string
  isError: boolean
  showDay: boolean
}
export default defineComponent({
  name: 'BaseCalendar',
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    showDay: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
  components: {
    Calendar,
  },
  setup(props: Props, context: SetupContext) {
    // data
    const date = ref<string>(props.value.replace(/-/g, '/'))
    const isDayOnly = ref<boolean>(props.showDay)
    const tempDate = ref<string>('')
    const open = ref<boolean>(false)
    const viewableObj = reactive<{ [key: string]: string | boolean }>({
      viewable: true,
      top: '',
    })

    // computed
    const wrap = computed(() => {
      return viewableObj.viewable ? '' : 'relative'
    })
    const errorStatus = computed(() => {
      return props.isError ? 'border-red_ea1 bg-red_fae' : ''
    })
    const dateString = computed({
      get: (): string => {
        if (date.value === '') return ''
        return isDayOnly.value
          ? format(new Date(date.value), 'yyyy/MM/dd')
          : format(new Date(date.value), 'yyyy/MM/dd HH:mm')
      },
      set: (inputDate: string) => {
        if (inputDate === '') {
          date.value = ''
          backDate(true)
          return
        }
        inputDate = inputDate.trim().replace(/\s+/, ' ')
        const RE =
          /^\d{4}(\/|-)(0[1-9]|1[0-2])(\/|-)(0[1-9]|[1-2]\d|3[0-1])\s+(20|21|22|23|[0-1]\d):(00|30)$/
        const REDayOnly =
          /^\d{4}(\/|-)(0[1-9]|1[0-2])(\/|-)(0[1-9]|[1-2]\d|3[0-1])$/
        if (isDayOnly.value && REDayOnly.test(inputDate)) {
          tempDate.value = inputDate + ' ' + '12:00:00'
        } else if (RE.test(inputDate)) {
          tempDate.value = inputDate
        }
      },
    })

    // watch
    watch(tempDate, (newValue: string) => handlerTempDate(newValue))
    watch(date, (newValue: string) => handlerDate(newValue))
    watch(open, (newFlg: boolean) => handleOpen(newFlg))

    // mounted
    onMounted(() => {
      if (date.value !== '') {
        const RE = /^\d{4}(\/|-)(0[1-9]|1[0-2])(\/|-)(0[1-9]|[1-2]\d|3[0-1])$/
        if (RE.test(date.value)) isDayOnly.value = true
        tempDate.value = date.value
      }
    })

    // methods
    const toggleCalendar = (toggle: boolean) => {
      if (tempDate.value === '') {
        tempDate.value = format(new Date(), 'yyyy/MM/dd') + ' ' + '12:00:00'
      }
      open.value = toggle
    }
    const backDate = (empty?: boolean) => {
      if (empty) return ''
      const strDate = isDayOnly.value
        ? tempDate.value.split(' ')[0]
        : tempDate.value
      context.emit('input', strDate)
    }
    // watchHandle
    const handlerTempDate = (n: string) => {
      date.value = format(new Date(n), 'yyyy/MM/dd HH:mm:ss')
      backDate()
    }
    const handlerDate = (n: string) => {
      if (n !== '') {
        tempDate.value = format(new Date(n), 'yyyy/MM/dd HH:mm:ss')
      }
    }
    const handleOpen = (n: boolean) => {
      if (n && dateString.value === '') {
        dateString.value = tempDate.value.substring(
          0,
          tempDate.value.length - 3
        )
      }
    }

    return {
      open,
      isDayOnly,
      tempDate,
      viewableObj,
      dateString,
      errorStatus,
      wrap,
      toggleCalendar,
    }
  },
})
