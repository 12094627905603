














import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
  },
})
