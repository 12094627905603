





import { defineComponent, SetupContext } from '@vue/composition-api'
export default defineComponent({
  props: {
    iconClass: { type: String, required: true },
    className: { type: String },
  },
  setup(_, context: SetupContext) {
    const handleClick = () => {
      context.emit('click')
    }

    return {
      handleClick,
    }
  },
})
