











import { defineComponent, PropType, computed } from '@vue/composition-api'
import { TypeColumn } from '~/src/types/common'

type Props = {
  column: TypeColumn
}
export default defineComponent({
  props: {
    column: {
      type: Object as PropType<TypeColumn>,
    },
  },
  setup(props: Props) {
    const title = computed(() => {
      if (props.column.show) {
        return props.column.show
      }
      return props.column.label
    })

    return {
      title,
    }
  },
})
