


























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { Option } from '~/src/types/common'

type Props = {
  value: string[] | number[]
  optionItems: Option[]
  height: string
}
export default defineComponent({
  props: {
    value: {
      type: Array as PropType<(string | number)[]>,
      required: true,
    },
    optionItems: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    height: {
      type: String,
      default: '396',
    },
  },
  setup(props: Props, context) {
    const selectedItems = ref<string[] | number[]>(props.value)

    watch(selectedItems, () => {
      context.emit('input', selectedItems.value)
    })

    return {
      selectedItems,
    }
  },
})
