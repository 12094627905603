

































































































import {
  defineComponent,
  reactive,
  ref,
  PropType,
  computed,
  SetupContext,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from '@vue/composition-api'
import { endOfMonth, subMonths, startOfDay, isBefore } from 'date-fns'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay/index'
import addMonths from 'date-fns/addMonths'
import addMinutes from 'date-fns/addMinutes'
import addDays from 'date-fns/addDays'

type ViewableObj = { [key: string]: string | boolean }
type Props = {
  value: string
  viewableObj: ViewableObj
  isDayOnly: boolean
}

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    viewableObj: {
      type: Object as PropType<ViewableObj>,
      required: true,
    },
    isDayOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    const {
      daySelected,
      year,
      toggleDay,
      togglePreYear,
      toggleNextYear,
      togglePreMonth,
      toggleNextMonth,
    } = toggleDaySelected()

    const {
      timer,
      timeSelectIndex,
      timerList,
      timerArrowUp,
      timerArrowDown,
      timerArrowSet,
      toggleTimer,
    } = useTimer()

    // $refs
    const wrap = ref<HTMLDivElement | null>(null)

    // data
    const date = ref<string>(props.value)
    const viewableObj = reactive(props.viewableObj)
    const week: string[] = ['日', '月', '火', '水', '木', '金', '土']

    // watch
    watch(date, (newValue: string) => handlerDate(newValue))
    watch(daySelected, (newDate: Date) => handlerDaySelected(newDate))
    watch(timeSelectIndex, (newValue: number) =>
      handlerTimeSelectIndex(newValue)
    )

    // mounted
    onMounted(() => {
      const dateArr = date.value.split(' ')
      const time = dateArr[1].split(':')
      timeSelectIndex.value = (Number(time[0]) * 60 + Number(time[1])) / 30
      date.value = dateArr[0] + ' ' + timerList.value[timeSelectIndex.value]
      daySelected.value = new Date(dateArr[0])
      timerArrowSet(timeSelectIndex.value)
      viewableObj.viewable = isView()
      if (!viewableObj.viewable) {
        viewableObj.top = `-${wrap.value!.getBoundingClientRect().height}px`
      }
    })

    // beforeDestroy
    onBeforeUnmount(() => {
      viewableObj.viewable = true
      viewableObj.top = ''
    })

    // computed
    const dayMonth = computed((): string => {
      return format(daySelected.value, 'M/d')
    })
    const month = computed((): string => {
      return format(daySelected.value, 'M')
    })
    const preMonth = computed((): string => {
      return format(subMonths(daySelected.value, 1), 'MM')
    })
    const nextMonth = computed((): string => {
      return format(addMonths(daySelected.value, 1), 'MM')
    })
    const endDayMouthIndex = computed((): number => {
      return getDay(endOfMonth(daySelected.value))
    })
    const preMouthEndDayIndex = computed((): number => {
      return getDay(endOfMonth(subMonths(daySelected.value, 1)))
    })
    const preMouthLastDay = computed((): number => {
      return Number(format(endOfMonth(subMonths(daySelected.value, 1)), 'dd'))
    })
    const currentMouthEndDay = computed((): number => {
      return Number(format(endOfMonth(daySelected.value), 'dd'))
    })
    const dateArray = computed((): string[][] => {
      const preMouthAry = () => {
        let ary: string[] = []
        if (preMouthEndDayIndex.value === 6) {
          return ary
        }
        for (
          let index: number = preMouthLastDay.value, j = 0;
          j <= preMouthEndDayIndex.value;
          index--, j++
        ) {
          ary = [...[preMonth.value + '/' + String(index)], ...ary]
        }
        return ary
      }
      const nextMouthAry = () => {
        let ary: string[] = []
        for (let index = 1, j = endDayMouthIndex.value; j < 6; index++, j++) {
          ary = [...ary, ...[nextMonth.value + '/' + String(index)]]
        }
        return ary
      }
      const mouthSize = () => {
        let ary: string[] = []
        for (let index = 1; index <= currentMouthEndDay.value; index++) {
          ary = [...ary, ...[month.value + '/' + String(index)]]
        }
        return ary
      }
      const calendarArray = [
        ...preMouthAry(),
        ...mouthSize(),
        ...nextMouthAry(),
      ]
      let index = 0
      const newArray = []
      while (index < calendarArray.length) {
        newArray.push(calendarArray.slice(index, (index += 7)))
      }
      return newArray
    })
    const tableStyle = computed((): string => {
      return props.isDayOnly ? 'border my-11px' : 'border mr-10px my-11px'
    })

    const wrapStyle = computed((): string => {
      return props.isDayOnly
        ? 'shadow-gray w-244px border font-hiragino-w3 py-12px pl-9px pr-12px m-auto bg-white'
        : 'shadow-gray w-315px border font-hiragino-w3 py-12px pl-9px pr-12px m-auto bg-white'
    })

    // methods
    const dayFilter = (target: string) => {
      return target.split('/')[1]
    }
    const timeFilter = (target: string) => {
      const timeArr = target.split(':')
      return timeArr[0] + ':' + timeArr[1]
    }
    const activeDay = (date: string): string => {
      if (date.split('/')[0] !== month.value) {
        return 'text-gray_d4d'
      }
      if (date === dayMonth.value) {
        return ['bg-blue_197', 'text-white'].join(' ')
      }
      return 'hover:bg-gray_d4d'
    }
    const activeTime = (key: number): string => {
      if (timeSelectIndex.value === key) {
        return ['bg-blue_197', 'text-white'].join(' ')
      }
      return 'hover:bg-gray_d4d'
    }

    // watch
    const handlerDate = (newValue: string) => {
      context.emit('input', newValue)

      daySelected.value = new Date(newValue)
      const h = Number(newValue.split(' ')[1].split(':')[0])
      const m = Number(newValue.split(' ')[1].split(':')[1])
      toggleTimer((h * 60 + m) / 30)
      timerArrowSet(timeSelectIndex.value)
      nextTick(() => {
        if (!viewableObj.viewable)
          viewableObj.top = `-${wrap.value!.getBoundingClientRect().height}px`
      })
    }
    const handlerDaySelected = (newValue: Date) => {
      date.value =
        format(newValue, 'yyyy/MM/dd') + ' ' + date.value.split(' ')[1]
    }
    const handlerTimeSelectIndex = (newValue: number) => {
      date.value = date.value.split(' ')[0] + ' ' + timerList.value[newValue]
    }
    // emit
    const handleClick = () => {
      context.emit('click')
    }
    const isView = (): boolean => {
      if (!wrap.value) return false

      const rect = wrap.value.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      )
    }

    return {
      wrap,
      timer,
      week,
      month,
      year,
      dateArray,
      timerList,
      tableStyle,
      wrapStyle,
      toggleDay,
      togglePreYear,
      toggleNextYear,
      togglePreMonth,
      toggleNextMonth,
      toggleTimer,
      timerArrowUp,
      timerArrowDown,
      dayFilter,
      timeFilter,
      activeDay,
      activeTime,
      handleClick,
    }
  },
})

const toggleDaySelected = () => {
  const daySelected = ref<Date>(new Date())
  const year = computed((): string => {
    return format(daySelected.value, 'yyyy')
  })

  const toggleDay = (item: string) => {
    daySelected.value = new Date(year.value + '/' + item)
  }
  const togglePreYear = () => {
    daySelected.value = subMonths(daySelected.value, 12)
  }
  const toggleNextYear = () => {
    daySelected.value = addMonths(daySelected.value, 12)
  }
  const togglePreMonth = () => {
    daySelected.value = subMonths(daySelected.value, 1)
  }
  const toggleNextMonth = () => {
    daySelected.value = addMonths(daySelected.value, 1)
  }

  return {
    daySelected,
    year,
    toggleDay,
    togglePreYear,
    toggleNextYear,
    togglePreMonth,
    toggleNextMonth,
  }
}

const useTimer = () => {
  // data
  const timer = ref<HTMLInputElement | null>(null)
  const timeSelectIndex = ref<number>(24)
  const cellH = 27

  // computed
  const timerList = computed((): string[] => timerCut())
  const timerCut = (): string[] => {
    let timer: string[] = []
    let timeTemp = startOfDay(new Date())
    const nextDay = addDays(timeTemp, 1)
    for (; isBefore(timeTemp, nextDay); timeTemp = addMinutes(timeTemp, 30)) {
      timer = [...timer, ...[format(timeTemp, 'HH:mm:ss')]]
    }
    return timer
  }

  // methods
  const timerArrowUp = () => {
    timer.value!.scrollTop -= 6 * cellH
  }
  const timerArrowDown = () => {
    timer.value!.scrollTop += 6 * cellH
  }
  const timerArrowSet = (index = 6) => {
    timer.value!.scrollTop = index * cellH
  }
  const toggleTimer = (index: number) => {
    timeSelectIndex.value = index
  }

  return {
    timer,
    timeSelectIndex,
    timerList,
    timerArrowUp,
    timerArrowDown,
    timerArrowSet,
    toggleTimer,
  }
}
