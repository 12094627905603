











import { defineComponent, PropType, SetupContext } from '@vue/composition-api'
import { TypeColumn, TypeRow } from '~/src/types/common'
import TableCell from './TableCell.vue'

export default defineComponent({
  components: {
    TableCell,
  },
  props: {
    columns: {
      type: Array as PropType<TypeColumn[]>,
      required: true,
    },
    row: {
      type: Object as PropType<TypeRow>,
    },
  },
  setup(_, context: SetupContext) {
    const clickRow = (row: TypeRow) => {
      context.emit('clickRow', row)
    }

    return {
      clickRow,
    }
  },
})
