
















import { defineComponent, computed, SetupContext } from '@vue/composition-api'

type Props = {
  value: string
  width: string
  type: string
  placeholder: string
  disabled: boolean
  readonly: string
  maxlength: number
  isError: boolean
}
export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    width: {
      type: String,
      default: 'max-w-sm sm:max-w-md md:max-w-lg lg:max-w-3xl xl:max-w-3xl',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // computed
    const disableClass = computed((): string => {
      return props.disabled
        ? 'bg-gray_f0f text-gray_adb cursor-not-allowed'
        : ''
    })
    const errorClass = computed((): string => {
      return props.isError
        ? 'border-red_ea1 bg-red_fae'
        : 'focus:border-blue_3f9 focus:shadow-blue-3f9'
    })
    // methods
    const handleInput = (e: Event): void => {
      const target = e.target as HTMLInputElement
      context.emit('input', target.value)
    }

    return {
      disableClass,
      errorClass,
      handleInput,
    }
  },
})
