

































import {
  defineComponent,
  SetupContext,
  getCurrentInstance,
  ref,
} from '@vue/composition-api'

type Props = {
  isMenuShow: boolean
}
export default defineComponent({
  props: {
    isMenuShow: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const vm = getCurrentInstance()
    const isMobile = ref<boolean>(Boolean(vm!.proxy.$device.mobile))
    const isMenuOpen = ref<boolean>(props.isMenuShow)

    const clickSidebar = () => {
      context.emit('click')
    }
    const logout = () => {
      context.emit('logout')
    }

    return {
      isMobile,
      isMenuOpen,
      clickSidebar,
      logout,
    }
  },
})
