



































import { defineComponent, reactive, SetupContext } from '@vue/composition-api'

type Form = {
  isBold: boolean
  isUnderline: boolean
  color: string
  context: string
  isFirst: boolean
}
type Props = {
  value: string
  isEditMode: boolean
  width: string
  height: string
}
export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '700px',
    },
    height: {
      type: String,
      default: '500px',
    },
  },
  setup(props: Props, context: SetupContext) {
    const form = reactive<Form>({
      isBold: false,
      isUnderline: false,
      color: '#000000',
      context: props.value,
      isFirst: true,
    })

    const changeColor = () => {
      console.log(form.color)
      document.execCommand('foreColor', false, form.color)
    }

    const changeBold = () => {
      form.isBold = !form.isBold
      document.execCommand('bold', false)
    }

    const changeUnderline = () => {
      form.isUnderline = !form.isUnderline
      document.execCommand('underline', false)
    }

    const updateContext = (e: Event) => {
      const input = e.target as HTMLElement
      context.emit('input', input.innerHTML)
    }

    return {
      form,
      changeColor,
      changeBold,
      changeUnderline,
      updateContext,
    }
  },
})
