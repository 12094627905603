


















































import {
  defineComponent,
  PropType,
  SetupContext,
  ref,
  toRef,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import { Option } from '~/src/types/common'
import BaseMultiSelectForm from './BaseMultiSelectForm.vue'
import BaseSearchForm from './BaseMultiSelectSearch.vue'

type Props = {
  value: Option[]
  originalItems: Option[]
  placeholder: string
}

export default defineComponent({
  components: {
    BaseMultiSelectForm,
    BaseSearchForm,
  },
  props: {
    value: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    originalItems: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    // data
    const originalItems = toRef(props, 'originalItems')
    const filteredItems = ref<Option[]>(props.originalItems)
    const filteredCheckedItems = ref<(string | number)[]>([])
    const selectedCheckedItems = ref<(string | number)[]>([])
    const keyword = ref<string>('')

    // mounted
    onMounted(() => {
      window.addEventListener('click', deselectAll)
    })

    // beforeDestroy
    onBeforeUnmount(() => {
      window.removeEventListener('click', deselectAll)
    })

    // methods
    const addItems = () => {
      const totalItems = [
        ...props.value.map((item: Option) => item.value),
        ...filteredCheckedItems.value,
      ]
      // v-modelの更新
      const selectedItems = originalItems.value.filter((item: Option) =>
        totalItems.includes(item.value)
      )
      context.emit('input', selectedItems)

      selectedCheckedItems.value = filteredCheckedItems.value
    }
    const deleteItems = () => {
      // v-modelの更新
      const selectedItems = props.value.filter(
        (item) => !selectedCheckedItems.value.includes(item.value)
      )
      context.emit('input', selectedItems)
    }
    const deselectAll = (event: Event) => {
      const multiSelectElements = Array.from(
        document.getElementsByClassName('MultiSelectElement')
      )

      if (!multiSelectElements.find((element) => element === event.target)) {
        ;[selectedCheckedItems.value, filteredCheckedItems.value] = [[], []]
      }
    }
    const searchKeyword = () => {
      filteredItems.value = originalItems.value.filter((item: Option) =>
        item.text.toLowerCase().includes(keyword.value.toLowerCase())
      )
    }
    const toggleAllFilteredCheck = () => {
      if (filteredCheckedItems.value.length !== filteredItems.value.length) {
        filteredCheckedItems.value = filteredItems.value.map(
          (item) => item.value
        )
      } else {
        filteredCheckedItems.value = []
      }
    }

    const toggleAllSelectedCheck = () => {
      if (selectedCheckedItems.value.length !== props.value.length) {
        selectedCheckedItems.value = props.value.map((item) => item.value)
      } else {
        selectedCheckedItems.value = []
      }
    }

    return {
      filteredItems,
      filteredCheckedItems,
      selectedCheckedItems,
      keyword,
      addItems,
      deleteItems,
      deselectAll,
      searchKeyword,
      toggleAllFilteredCheck,
      toggleAllSelectedCheck,
    }
  },
})
