
















import { defineComponent, SetupContext, computed } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-bridge'

type Props = {
  serviceName: string
  count: number
  icon: string
}
export default defineComponent({
  props: {
    serviceName: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    // i18n
    const { t } = useI18n()

    const isSetCount = computed(() => {
      return props.count !== null
    })
    const handleClick = (): void => {
      context.emit('click')
    }

    return {
      t,
      isSetCount,
      handleClick,
    }
  },
})
