





















import {
  defineComponent,
  toRef,
  computed,
  SetupContext,
} from '@vue/composition-api'

type Props = {
  base64: string | null
  fileName: string
  disabled: boolean
}
export default defineComponent({
  props: {
    base64: {
      type: String,
      required: true,
      default: null,
    },
    fileName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props, context: SetupContext) {
    // data
    const base64 = toRef(props, 'base64')

    // computed
    const isDisabled = computed((): boolean => {
      if (base64.value && href.value && props.fileName) {
        return props.disabled
      } else {
        return true
      }
    })
    const href = computed(() => {
      if (!base64.value) return ''

      if (base64.value.search(/^.*,/) > -1) {
        const data = base64.value.replace(/^.*,/, '')
        const bin = atob(data)
        const buffer = new Uint8Array(bin.length)

        for (let i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i)
        }

        const blob = new Blob([buffer.buffer])
        return window.URL.createObjectURL(blob)
      } else {
        return ''
      }
    })

    const checkEmit = () => {
      if (base64.value && href.value && props.fileName) {
        handleClick()
      }
    }
    const handleClick = () => {
      const download = document.createElement('a')
      download.href = href.value
      download.download = props.fileName
      download.click()

      context.emit('click')
    }

    return {
      isDisabled,
      checkEmit,
    }
  },
})
