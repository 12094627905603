






















import {
  defineComponent,
  PropType,
  computed,
  SetupContext,
} from '@vue/composition-api'
import { UploadFile } from '~/src/types/common'

type FileType =
  | 'txt'
  | 'csv'
  | 'jpg'
  | 'png'
  | 'gif'
  | 'pdf'
  | 'zip'
  | 'docx'
  | 'xlsx'

type Props = {
  disabled: boolean
  fileType: FileType[]
}
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: Array as PropType<FileType[]>,
      default: () => ['txt', 'csv', 'jpg', 'png', 'gif', 'pdf', 'zip'],
    },
  },
  setup(props: Props, context: SetupContext) {
    const allowedMimeList = computed((): string[] => {
      const mimeList: string[] = []

      props.fileType.forEach((type) => {
        switch (type) {
          case 'txt':
            mimeList.push('text/plain')
            break

          case 'csv':
            mimeList.push('text/csv')
            break

          case 'jpg':
            mimeList.push('image/jpeg')
            break

          case 'png':
            mimeList.push('image/png')
            break

          case 'gif':
            mimeList.push('image/gif')
            break

          case 'pdf':
            mimeList.push('application/pdf')
            break

          case 'zip':
            mimeList.push('application/zip')
            break

          case 'docx':
            mimeList.push(
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            )
            break

          case 'xlsx':
            mimeList.push(
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
            break
        }
      })

      return mimeList
    })

    // methods
    const setFile = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (!target) return

      const file: File | null = target.files ? target.files[0] : null
      const reader = new FileReader()
      let uploadFile: UploadFile = {
        name: '',
        size: 0,
        type: '',
        data: '',
        message: '',
      }

      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          if (reader.result) {
            if (allowedMimeList.value.find((mime) => mime === file.type)) {
              uploadFile = {
                ...uploadFile,
                ...{
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  data: reader.result,
                },
              }
            } else {
              uploadFile.message = 'この種類のファイルは選択できません'
            }
          } else {
            uploadFile.message = 'ファイルの読み込みに失敗しました'
          }

          fileChange(uploadFile)
        }
      }
    }
    const fileChange = (uploadFile: UploadFile) => {
      context.emit('fileChange', uploadFile)
    }

    return {
      setFile,
    }
  },
})
